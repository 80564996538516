import manufacturingApi from "@/api/manufacturingApi"


/**** PRODUCTS ENDPOINTS *****/

export const getProducts = async () => {
    const { data } = await manufacturingApi.get('/products/', { withCredentials: true } )
    return data
}

export const insertProduct = async (data) => {
    return await manufacturingApi.post('/products/', data, { withCredentials: true } )
}

export const updateProduct = async (id, data) => {
    await manufacturingApi.put(`/products/${id}/`, data, { withCredentials: true } )
}

export const deleteProduct = async (id) => {
    await manufacturingApi.delete(`/products/${id}/`, { withCredentials: true } )
}


/**** WORK ORDERS ENDPOINTS *****/

export const getOrders = async (query_params) => {
    const { data } = await manufacturingApi.get('/orders/', { 
        withCredentials: true,
        params: query_params
    } )
    return data
}

export const insertOrder = async (data) => {
    return await manufacturingApi.post('/orders/', data, { withCredentials: true } )

}

export const updateOrder = async (id, data) => {
    await manufacturingApi.put(`/orders/${id}/`, data, { withCredentials: true } )

}

export const deleteOrder = async (id) => {
    await manufacturingApi.delete(`/orders/${id}/`, { withCredentials: true } )

}


/**** USERS ENDPOINTS *****/

export const getUsers = async () => {
    const { data } = await manufacturingApi.get('/auth/users/', { withCredentials: true } )
    return data
}

export const insertUser = async (data) => {
    return await manufacturingApi.post('/auth/users/', data, { withCredentials: true } )

}

export const updateUser = async (id, data) => {
    await manufacturingApi.patch(`/auth/users/${id}/`, data, { withCredentials: true } )

}

export const deleteUser = async (id) => {
    await manufacturingApi.delete(`/auth/users/${id}/`, { withCredentials: true } )

}


/**** REGISTRIES ENDPOINTS *****/

export const getRegistries = async (query_params) => {
    const { data } = await manufacturingApi.get('/registries/', { 
        withCredentials: true,
        params: query_params
    })
    return data
}

export const insertRegistry = async (data) => {
    return await manufacturingApi.post('/registries/', data, { withCredentials: true } )

}

export const updateRegistry = async (id, data) => {
    await manufacturingApi.put(`/registries/${id}/`, data, { withCredentials: true } )

}

export const deleteRegistry = async (id) => {
    await manufacturingApi.delete(`/registries/${id}/`, { withCredentials: true } )

}


/**** NOTIFICATIONS ENDPOINTS *****/

export const getNotifications = async (query_params={}) => {
    const { data } = await manufacturingApi.get('/notifications/', { 
        withCredentials: true,
        params: query_params
    })
    return data
}

export const insertNotification = async (data) => {
    return await manufacturingApi.post('/notifications/', data, { withCredentials: true } )

}

export const updateNotification = async (id, data) => {
    await manufacturingApi.put(`/notifications/${id}/`, data, { withCredentials: true } )

}

export const deleteNotification = async (id) => {
    await manufacturingApi.delete(`/notifications/${id}/`, { withCredentials: true } )

}

/*** DASHBOARD ENDPOINTS ***/

export const getOrdersInfo = async () => {
    const { data } = await manufacturingApi.get('/orders/info', { withCredentials: true } )
    return data
}

export const getFinishedOrders = async (start_date, end_date) => {
    if (!start_date || !end_date) {
        const { data } = await manufacturingApi.get(
            `/orders/finished/`, { withCredentials: true } )
        return data
    }
    
    let start_date_formatted = start_date.toISOString().split('T')[0]
    let end_date_formatted = end_date.toISOString().split('T')[0]
    const { data } = await manufacturingApi.get(
        `/orders/finished/${start_date_formatted}/${end_date_formatted}`, 
        { withCredentials: true } )
    return data
}

export const getOrderNotifications = async (idOrder) => {
    const { data } = await manufacturingApi.get(`orders/${idOrder}/notifications/`, { withCredentials: true } )
    return data
}


export const getOrderInfoDetails = async (idOrder) => {
    const { data } = await manufacturingApi.get(`/orders/info/${idOrder}`, { withCredentials: true } )
    return data
}

export const getProductsInfo = async () => {
    const { data } = await manufacturingApi.get('/products/info', { withCredentials: true } )
    return data
}

export const getProductInfoDetails = async (idProduct) => {
    const { data } = await manufacturingApi.get(`/products/info/${idProduct}`, { withCredentials: true } )
    return data
}

export const getClosedRegistries = async (start_date, end_date) => {
    let start_date_formatted = start_date.toISOString().split('T')[0]
    let end_date_formatted = end_date.toISOString().split('T')[0]
    const { data } = await manufacturingApi.get(
        `/registries/closed/${start_date_formatted}/${end_date_formatted}`, 
        { withCredentials: true } )
    return data
}
